
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Line from '@/components/Line.vue'

export default defineComponent({
  name: 'AboutMember',
  components: { Line, PageWithHeader, Pic },
  setup () {
    return {
      list: [{
        question: 'membershipintro_3',
        answer: 'membershipintro_4',
      }, {
        question: 'membershipintro_5',
        answer: 'membershipintro_6',
      }, {
        question: 'membershipintro_7',
        answer: 'membershipintro_8',
      }],
    }
  },
})
